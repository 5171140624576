import { createRoot } from "@audienceplus/mask-maker/lib/micro-frontend";

/**
 * TODO Add docs
 *
 * Basic fetch with retries
 *
 * @private
 * @param {string} url - value URL
 * @param {number} maxRetries - default to 3 retries
 * @param {number} retryDelay - default to 100ms
 * @param {number} maxDelay - default to 500ms
 * @returns {Promise<any>} - Returns data from request
 */
function fetchWithRetry(url, maxRetries = 3, retryDelay = 100, maxDelay = 500) {
  let retries = 0;

  function fetching() {
    return fetch(url)
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw new Error(
          `Error fetching graph.json! Status: ${response.status}`
        );
      })
      .catch((error) => {
        console.error(
          `Graph.json fetching error on attempt ${retries + 1}:`,
          error
        );
        retries++;
        if (retries < maxRetries) {
          return new Promise((resolve) =>
            setTimeout(resolve, Math.min(retryDelay * retries, maxDelay))
          ).then(fetching);
        } else {
          throw new Error(
            `Failed after ${maxRetries} attempts fetching graph.json`
          );
        }
      });
  }

  return fetching();
}

/**
 * @todo add error handling
 */

const graphUrl = new URL(
  "graph.json",
  window.location.href.replace(/^([^?#&]*).*/, "$1").replace(/\/?$/, "/")
);

const test = async (url, maxRetries = 3, retryDelay = 100, maxDelay = 500) => {
  let retries = 0;

  function fetching() {
    return fetch(url, {
      method: "HEAD",
      keepalive: true,
    })
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw new Error(`Error fetching Connector! Status: ${response.status}`);
      })
      .catch((error) => {
        console.error(`Connector fetching error on attempt ${retries + 1}:`, error);
        retries++;
        if (retries < maxRetries) {
          return new Promise((resolve) =>
            setTimeout(resolve, Math.min(retryDelay * retries, maxDelay))
          ).then(fetching);
        } else {
          throw new Error(`Failed after ${maxRetries} attempts fetching Connector`);
        }
      });
  }

  return fetching();
};

const connector = /\.dev/.test(window.location.href)
  ? "https://content.audienceplus.dev/"
  : "https://content.audienceplus.com/";
await test(connector);

fetchWithRetry(graphUrl)
  .then((res) => res.json())
  .then((graph) => {
    let container = document.getElementById("_apRoot");
    if (!container) {
      container = document.createElement("div");
      container.id = "_apRoot";
      document.body.insertBefore(container, document.body.firstChild);
    }

    const root = createRoot(container);
    root.render(graph);
  });
